import './App.css';
import {
  RouterProvider,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './config/i18n.js';  // Import i18n configuration
import { useEffect } from 'react';
import { useLoading } from './store/LoadingContext';
import SplashScreen from './shared/components/SplashScreen/SplashScreen.js';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import router from './utils/router.js'

function App() {
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    // Trigger loading state when the location changes (start of navigation)
    setLoading(true);

    const elements = document.querySelectorAll('.transition-element');
    elements.forEach((element) => {
      element.classList.add('transition-effect');
    });

    TagManager.initialize({ gtmId: 'GTM-TLSRJDTN' });  // Insira o seu GTM-ID

    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize('G-E1D7TCLNZ0');    
    // Send initial pageview
    ReactGA.send('pageview');

    // Mock a delay to simulate page load (e.g., fetching data)
    const timer = setTimeout(() => {
      // End of navigation (loading complete)
      setLoading(false);
    }, 1000); // Adjust the delay as needed to reflect actual loading times

    return () => clearTimeout(timer); // Clean up on component unmount
  }, [ setLoading]);
  
  return (
    <div className="App">
      {loading && <SplashScreen />}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;