import React, { createContext, useState, useContext } from "react";
import { UAParser } from "ua-parser-js";

const UserAgentContext = createContext();
export const useUserAgentContext = () => useContext(UserAgentContext);

export const UserAgentProvider = ({children}) => {
    console.log("")
    const [useAgent, setUserAgent] = useState({});

    const parser = new UAParser(navigator.userAgent);
    const parsed = parser.getResult();
    setUserAgent({
        browser: parsed.browser.name,
        version: parsed.browser.version,
        os: parsed.os.name,
        device: parsed.device.type
    })

    return (
        <UserAgentContext.Provider value={{ useAgent, setUserAgent }}>
            {children}
        </UserAgentContext.Provider>
    )
  };
