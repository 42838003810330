import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap'; // Import Alert component
import { useLoading } from "../../../store/LoadingContext";
import config from '../../../config.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const apiUrl = config.apiUrl;

const EmailModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { loading, setLoading } = useLoading();
  const [responseMessage, setResponseMessage] = useState("");
  const { t } = useTranslation("emailModal");

  const handleSubmit = () => {
    // Validações básicas de email
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError("Por favor, insira um email válido.");
      return;
    }
    console.log("Email para newsletter:", email);
    registerNewsletter();
  };

  const registerNewsletter = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}/newsletters`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      const responseStatus = response.status;

      if (responseStatus === 201) {
        // Sucesso
        // setResponseMessage("Inscrição realizada com sucesso! 🎉");
        handleClose();
        localStorage.setItem("newsletterSubmited", true);
        setError(false);
        setEmail("");  // Limpar o email após sucesso
      } else {
        // Caso ocorra algum erro com o status
        setResponseMessage("Houve um erro ao registrar seu email. Tente novamente.");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setResponseMessage("Não foi possível registrar seu email no momento. Tente novamente mais tarde.");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");  // Limpar o erro ao alterar o email
    setResponseMessage(""); // Limpar a mensagem ao alterar o email
  };

  const handleCloseModal = () => {
    handleClose();
    setEmail(""); // Limpar o campo de email
    setError(""); // Limpar erros
    setResponseMessage(""); // Limpar mensagem
  };

  return (
    <Modal show={show} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">{t('newsletterModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-4">
          {t('newsletterModal.bodyText')}
        </p>

        {/* Display success or error message */}
        {responseMessage && (
          <Alert variant={error ? "danger" : "success"}>{responseMessage}</Alert>
        )}

        <Form>
          <Form.Group controlId="formEmail">
            <Form.Label>{t('newsletterModal.emailLabel')}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t('newsletterModal.emailPlaceholder')}
              value={email}
              onChange={handleEmailChange}
              isInvalid={!!error}
            />
            <Form.Control.Feedback type="invalid">
              {error || t('newsletterModal.errorMessage')}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          {t('newsletterModal.closeButton')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? t('newsletterModal.sending') : t('newsletterModal.submitButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );

};

export default EmailModal;
