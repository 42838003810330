import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useTranslation } from "react-i18next";
import config from '../../../config.js';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useLoading } from "../../../store/LoadingContext.js";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

const apiUrl = config.apiUrl;

const LoginContainer = ({
  t,
  emailPhoneInput,
  handleEmailInput,
  isEmailValid,
  handleSendCode,
  isCodeSent,
  responseMessage,
  loading,
  error
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div
        className="login-container bg-white p-4 rounded shadow text-center mx-auto"
        style={{ maxWidth: "400px" }}
      >
        {loading ? (
          <>
            <h2 className="text-primary">Carregando...</h2>
            <Button className="mt-4" variant="success" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          </>
        ) : (
          <>
            <i className="bi bi-box-arrow-in-right text-primary" style={{ fontSize: 50 }}></i>
            <h2 className="text-primary">{t("loginPage.title")}</h2>
            <hr />
            {isCodeSent && !error && (
              <Alert variant="success" className="mt-3">
                <i className="bi bi-envelope-check-fill me-2"></i>
                {t("loginPage.codeSentSuccess")}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" className="mt-3">
                <i className="bi bi-envelope-x-fill me-2"></i>
                {responseMessage}
              </Alert>
            )}
            <div className="input-group position-relative mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                placeholder={t("loginPage.placeholder")}
                value={emailPhoneInput}
                onChange={handleEmailInput}
              />
              <span
                className="input-icon position-absolute"
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                📧
              </span>
            </div>
            <button
              id="sendCodeBtn"
              className={`btn w-100 mt-2 btn-lg ${isEmailValid ? 'btn-primary' : 'btn-secondary'}`}
              onClick={handleSendCode}
              disabled={!isEmailValid}
            >
              {t("loginPage.sendCode")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};


const Login = () => {
  const { t } = useTranslation();
  const [emailPhoneInput, setEmailPhoneInput] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const { i18n } = useTranslation(); // Hook from react-i18next
  const languagesMap = {"en": "en-US", "pt": "pt-BR", "es":"es-ES"};
  const currentLanguage = i18n.language;
  const languageCode = languagesMap[currentLanguage];
  const {loading, setLoading } = useLoading();
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  // Função para validar o e-mail
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Função chamada no evento de input
  const handleEmailInput = (e) => {
    const value = e.target.value;
    setEmailPhoneInput(value);
    setIsEmailValid(validateEmail(value));
  };

  // Função para enviar o código e mostrar a mensagem de confirmação
  const handleSendCode = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}/secs/magic?language=${languageCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eMail: emailPhoneInput,
          }),
        }
      );

      const responseStatus = response.status;
      const responseBody = await response.json();

      if (responseStatus === 201) {
        const token = responseBody.token;
        // setIsCodeSent(true);
        setError(false);
        localStorage.setItem("token", JSON.stringify(token));
        navigate(`/quiz-by-exam`);
      } else {
        const errorDescription = responseBody.description; 
        setResponseMessage(errorDescription);
        setIsCodeSent(false);
        setError(true);  
      }
    } catch (error) {
      console.log(error);
      let errorMessage = `Não foi possível enviar o código no momento. Tente novamente mais tarde`;
      setResponseMessage(errorMessage);
      setIsCodeSent(false);
      setError(true);
    }
    finally {
      setEmailPhoneInput("");
      setLoading(false);
    }
  };

  return (
    <>
      <LoginContainer
        t={t}
        emailPhoneInput={emailPhoneInput}
        handleEmailInput={handleEmailInput}
        isEmailValid={isEmailValid}
        handleSendCode={handleSendCode}
        isCodeSent={isCodeSent}
        responseMessage={responseMessage}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default Login;
