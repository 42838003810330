import React, { useState, useEffect, startTransition, Suspense } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import './BlogArticle.css'; // Importar o CSS para o componente
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { createBucketClient } from '@cosmicjs/sdk'
import { useLoading } from "../../../store/LoadingContext";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share';
import i18n from 'i18next'; // Assuming you have i18n set up in your app

const Article = ({ id, title, text, description, thumbnail, relatedArticles }) => {
  const { t } = useTranslation('blogArticle');
  const shareUrl = window.location.href;
  const languageCode = i18n.language;

  return (
    <div className="article-container">
      <header className="article-header text-center">
        <h1 className="article-title">{title}</h1>
        <img
          src={thumbnail}
          className="thumbnail-image mt-2"
          alt={t('relatedArticles.article1.title')}
        />
      </header>

      <div className="share-buttons text-center ">
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={40} round className='mx-2'  />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={40} round className='mx-2'  />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} title={title}>
          <WhatsappIcon size={40} round className='mx-2'  />
        </WhatsappShareButton>
        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedinIcon size={40} round className='mx-2'  />
        </LinkedinShareButton>
      </div>

      <div className="summary-box">
        <strong>{t('article.summary')}:</strong>&nbsp;<span>{description}</span>
      </div>

      {/* <TranslateText text={text} sourceLang="pt" targetLang={languageCode} className="article-text" /> */}
      <p dangerouslySetInnerHTML={{ __html: text }} className="article-text"/>

      <RelatedArticles relatedArticles={relatedArticles}/>
    </div>
  );
};

const RelatedArticles = ({ relatedArticles }) => {
  const { t } = useTranslation('blogArticle');

  return (
    <div className="related-articles">
      <h3 className="text-center">{t('relatedArticles.title')}</h3>
      <div className="row g-4">
        { relatedArticles.length > 0 ? 
          relatedArticles.map((article) => (
            <div className="col-md-4" key={article.id}>
              <div onClick={() => { window.location.href=`/blog-article/${article.slug}`}}  className="card">
                <img src={article.thumbnail || "https://via.placeholder.com/350x200"} className="card-img-top" alt={article.title} />
                <div className="card-body">
                  <h5 className="card-title relatedArticleTitle">{article.title}</h5>
                  <p className="card-text">{article.description}</p>
                  <a href={`/blog-article/${article.slug}`} className="btn btn-primary">{t('relatedArticles.readMore')}</a>
                </div>
              </div>
            </div>
          ))
         : <p className="text-center">{t("relatedArticles.noFound")}</p>}

      </div>
    </div>
  );
};

const CommentsSection = () => {
  const { t } = useTranslation('blogArticle');

  return (
    <div className="comment-section">
      <h3>{t('comments.title')}</h3>
      <form className="comment-form">
        <div className="mb-3">
          <textarea className="form-control" rows="4" placeholder={t('comments.placeholder')}></textarea>
        </div>
        <button type="submit" className="btn btn-custom">{t('comments.submit')}</button>
      </form>
    </div>
  );
};

const BackButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('blogArticle');

  return ( 
    <div className="nav-container text-center">
    <button onClick={() =>{ navigate("/blog")}} className="btn btn-primary btn-home"><i className="bi bi-arrow-left"></i>&nbsp;{t("backButton.title")}</button>
    </div>
  )
}

const ArticlePage = () => {
  const { slug } = useParams();
  const cosmic = createBucketClient({
    bucketSlug: process.env.REACT_APP_BUCKET_SLUG || '',
    readKey: process.env.REACT_APP_BUCKET_READ_KEY || '',
  });
  
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [ thumbnail, setThumbnail] = useState("");
  const [ relatedArticles, setRelatedArticles] = useState([]);
  const { loading, setLoading } = useLoading();
  const props = ` {
    id
    slug,
    title,
    metadata {
      description,
      text,
      related_articles
    }
  }`;

  const findPostById = async (id) => {
    const { object: post } = await cosmic.objects.findOne({
      id: id,
      props: props,
    });
    return post;
  };

  const findPostBySlug = async (slug) => {
    const { object: post } = await cosmic.objects.findOne({
      slug: slug,
      props: props,
    });
    return post;
  };

  const fetchRelatedArticles = async (relatedIds) => {
    const articles = await Promise.all(
      relatedIds.map((rId) => findPostById(rId))
    );
    setRelatedArticles(articles);
  };

  const fetchPost = async (slug) => {
    
    try {
      setLoading(true);
      const post = await findPostBySlug(slug);
      console.log("Post:" + JSON.stringify(post));

      setTitle(post.title);
      setText(post.metadata.text);
      setDescription(post.metadata.description);
      setThumbnail(post.thumbnail);
      setId(post.id);
  
      if (post.metadata.related_articles) {
        fetchRelatedArticles(post.metadata.related_articles);
      }    
    } catch (err) {
      console.error('Error fetching post:', JSON.stringify(err));
      // setError('Failed to load post data'); // Set error message
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  useEffect(() => {
      if(slug){
        fetchPost(slug);
      }
      
  }, [slug]);

  return (
    <>
      <BackButton/>

      <div className="article-container">
          <Article
              id={id}
              title={title}
              text={text}
              description={description}
              thumbnail={thumbnail}
              relatedArticles={relatedArticles}
          />

      </div>    
      <BackButton/>
    </>    
  );
};

export default ArticlePage;
