import { createBrowserRouter } from "react-router-dom";
import Home from '../pages/StaticPage/Home/Home.js';
import Certification from '../pages/CertificationPage/Certification/Certification.js';
import Quiz from '../views/Quiz/Quiz.js';
import QuizResult from '../pages/QuizPage/QuizResult/QuizResult.js';
import Blog from '../pages/BlogPage/Blog/Blog.js';
import CertificationDetails from '../pages/CertificationPage/CertificationDetails/CertificationDetails.js';
import Login from '../pages/StaticPage/Login/Login.js';
import BlogArticle from '../pages/BlogPage/BlogArticle/BlogArticle.js';
import Dashboard from '../pages/AdminPage/Dashboard/dashboard.js';
import QuizByExam from '../pages/QuizPage/QuizByExam/QuizByExam.js';
import Contact from '../pages/StaticPage/Contact/Contact.js';
import NotFound from '../pages/StaticPage/NotFound/NotFound.js'; // Import the NotFound component
import QuizDashboard from '../pages/QuizPage/QuizDashboard/QuizDashboard.js';
import ExamQuizzes from '../pages/QuizPage/ExamQuizzes/ExamQuizzes.js';
import FeedbackForm from '../pages/QuizPage/FeedbackForm/FeedbackForm.js';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
      },
      {
        path: "/certification",
        element: <Certification/>,
      },
      {
        path: "/quiz/:questionId",
        element: <Quiz/>,
      },
      {
        path: "/quiz-result/:questionId",
        element: <QuizResult/>
      },
      {
        path: "/quiz-result",
        element: <QuizResult/>,
      },
      {
        path: "/blog",
        element: <Blog/>
      },
      {
        path: '/blog-article/:slug',
        element: <BlogArticle/>
      },
      {
        path: '/certification/:id',
        element: <CertificationDetails/>,
      },
      {
        path: '/login',
        element: <Login/>
      },
      {
        path: '/dashboard',
        element: <Dashboard/>
      },
      {
        path: '/quiz-by-exam',
        element: <QuizByExam/>
      },
      {
        path: '/contact',
        element: <Contact/>
      },
      {
        path: '/exam-quizzes/:id',
        element: <ExamQuizzes/>
      },
      {
        path: '/quiz-dashboard',
        element: <QuizDashboard/>
      },
      {
        path:'/feedback',
        element: <FeedbackForm/>
      },
      {
        path: "*", // Wildcard route to match any undefined path
        element: <NotFound />
      }
])

export default router;