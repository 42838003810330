import React, { useState } from 'react';
import Footer from '../../../shared/components/Footer/Footer';
import awsLogo from '../../../assets/img/aws-logo.png';
import azureLogo from '../../../assets/img/azure-logo.png';
import oracleLogo from '../../../assets/img/oracle-logo.png';
import solutionsArchitectAssociateLogo from '../../../assets/img/certifications/aws-sa-associate.png';
import developerAssociateLogo from '../../../assets/img/certifications/aws-developer.png';
import './Certification.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';

// Estilos customizados podem ser movidos para um arquivo CSS separado, se necessário
const styles = {
  navbar: {
    backgroundColor: '#000',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  awsLogo:{
    width: '60px',
    height: '30px',
    margin: '0 20px',
    cursor: 'pointer',
    transition: 'transform 0.3s',
  },
  providerLogo: {
    width: '80px',
    height: '80px',
    margin: '0 20px',
    cursor: 'pointer',
    transition: 'transform 0.3s',
  },
  certificationCard: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
  },
  cardTitle: {
    color: '#4caf50',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
};


// Filter Section Component
const FilterSection = ({ onFilter }) => (
  <section className="filter-section text-center py-4">
    <div className="container">
      <h2 className="mb-4">Filtrar por Provedor</h2>
      <div className="d-flex justify-content-center align-items-center">
        <img src={awsLogo} alt="AWS" className="provider-logo" style={styles.awsLogo} onClick={() => onFilter('AWS')} />
        <img src={azureLogo} alt="Azure" className="provider-logo" style={styles.providerLogo} onClick={() => onFilter('Azure')} />
        <img src={oracleLogo} alt="Oracle" className="provider-logo" style={styles.providerLogo} onClick={() => onFilter('Oracle')} />
        <button className="btn btn-outline-dark ms-3" onClick={() => onFilter('all')}>Todos</button>
      </div>
    </div>
  </section>
);

// Certification Card Component
const CertificationCard = ({ image, title, description }) => (
  <div className="col-md-4">
    <div className="certification-card p-3 h-100" style={styles.certificationCard}>
      <img src={image} alt={title} className="img-fluid" />
      <h5 style={styles.cardTitle}>{title}</h5>
      <p className="card-text">{description}</p>
      <a href="/certification/1" className="btn btn-success mt-3">Ver Detalhes</a>
    </div>
  </div>
);

// Certification List Component
const CertificationList = ({ certifications }) => (
  <div className="row g-4 text-center">
    {certifications.length > 0 ? (
      certifications.map(cert => (
        <CertificationCard key={cert.title} image={cert.image} title={cert.title} description={cert.description} />
      ))
    ) : (
      <div className="col-12">
        <div className="no-certifications text-center py-5">
          Nenhuma certificação encontrada para o provedor selecionado.
        </div>
      </div>
    )}
  </div>
);

// Main App Component
const App = () => {
  const [provider, setProvider] = useState('all');

  // Mock certifications data
  const certificationsData = [
    {
      provider: 'AWS',
      image: solutionsArchitectAssociateLogo,
      title: 'AWS Certified Solutions Architect',
      description: 'Simulados completos para a certificação AWS Solutions Architect.',
    },
    {
      provider: 'AWS',
      image: developerAssociateLogo,
      title: 'AWS Certified Developer',
      description: 'Prepare-se para o exame de AWS Certified Developer com simulados realistas.',
    },
    {
      provider: 'Azure',
      image: 'azure-fundamentals.png',
      title: 'Microsoft Azure Fundamentals',
      description: 'Questões simuladas que cobrem todos os tópicos para a certificação Azure Fundamentals.',
    },
    {
      provider: 'Azure',
      image: 'azure-administrator.png',
      title: 'Microsoft Azure Administrator',
      description: 'Simulados abrangentes para o exame de Azure Administrator.',
    },
    {
      provider: 'Oracle',
      image: 'oracle-certified-professional.png',
      title: 'Oracle Certified Professional',
      description: 'Simulados detalhados para a certificação Oracle Certified Professional.',
    },
    {
      provider: 'Oracle',
      image: 'oracle-sql-associate.png',
      title: 'Oracle Database SQL Certified Associate',
      description: 'Simulados completos para a certificação de SQL Oracle Associate.',
    },
  ];

  // Function to filter certifications based on provider
  const filteredCertifications = provider === 'all'
    ? certificationsData
    : certificationsData.filter(cert => cert.provider === provider);

  return (
    <div>
      <FilterSection onFilter={setProvider} />
      <section className="py-5">
        <div className="container">
          <CertificationList certifications={filteredCertifications} />
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default App;
