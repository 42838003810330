import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap'; // Import Modal from react-bootstrap
import { getApiLanguageCode } from '../../../../utils/getApiLanguageCode';
import i18n from 'i18next';
import axios from 'axios';
import config from '../../../../config';




export const Timer = ({ timeLeft, openConfirmationModal, currentQuiz }) => {
    const { t } = useTranslation('quiz');
    const apiUrl = config.apiUrl;
    const [remainingTime, setRemainingTime] = useState(timeLeft);
    const [showModal, setShowModal] = useState(false);
    const code = JSON.parse(localStorage.getItem('code'));
    const token = JSON.parse(localStorage.getItem("token"));
    const [messageToast, setMessageToast] = useState('')
    const [report, setReport] = useState({
      idCategory: '',
      description: '',
      code: code,
      token: token,
      idUserQuizQuestion: Number(currentQuiz.idUserQuizQuestion),
      questionNumber: currentQuiz.questionNumer,
      remainingTimeSeconds: currentQuiz.remainingTimeSeconds,
      totalQuestion: currentQuiz.totalQuestion,
      language: getApiLanguageCode(i18n.language)
      })
    const startTimeRef = useRef(performance.now());
    
    useEffect(() => {
      const updaterTimer = () => {
        const elapsed = (performance.now() - startTimeRef.current) / 1000
        const newRemainingTime = Math.max(timeLeft - elapsed, 0)

        setRemainingTime(newRemainingTime);

        if(newRemainingTime > 0) {
          requestAnimationFrame(updaterTimer)
        }
      }

      updaterTimer();

      return () => cancelAnimationFrame(updaterTimer);
    }, [timeLeft]);

    const handleChangeCategory = (event) => {
      setReport(prevReport => ({
        ...prevReport,
        idCategory: event.target.value
      }))
    }

    const handleChangeDescription = (event) => {
      setReport(prevReport => ({
        ...prevReport,
        description: event.target.value
      }))
    }

    const sendReport = async () => {
      const doc = document.getElementById('toast');
        doc.style.display = 'block'
      setReport(prevReport => ({
        ...prevReport,
      }));

      await fetch(`${apiUrl}/problems`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(report),
      }).then((e) => {
        setMessageToast(t('quiz.successReport'))
        console.log("Report criado com sucesso!!")
      });
      
    }

    const clearReport = () => {
      setShowModal(false);
      setReport(prevReport => ({
        ...prevReport,
        idCategory: '',
        description: ''
      }))
      setTimeout(() => {
        closeToast()
      }, 10000)
    }

    const closeToast = () => {
      const doc = document.getElementById('toast');
      doc.style.display = 'none'
    }

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);

        return `${t('quiz.timeRemaining')}: ${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }

    return (
        <div className="time-card mb-4 d-flex flex-column align-items-center">
          <button className="btn btn-timer text-center w-100 mt-2 text-bold">
            <i className="bi bi-clock"></i>
            &nbsp; 
            {formatTime(remainingTime)}
          </button> 
          
          <div className='container'>
            <div className='row d-flex justify-content-between w-100'>
            <button onClick={openConfirmationModal} className="col-6 btn btn-danger text-center text-white text-bold" style={{width: '48%'}}>
                <i className="bi bi-check-circle"></i> {t('quiz.finishQuiz')}
              </button>
              <button onClick={() => setShowModal(true)} className="col-6 btn btn-warning text-center text-white text-bold" style={{width: '48%'}}>
                <i className="bi bi-bug"></i> {t('quiz.reportAnProbrem')}
              </button>
            </div>
          </div>

          <Modal show={showModal} onHide={() => {
            setShowModal(false)
            clearReport()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{t('quiz.reportAnProbrem')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <label for="exampleFormControlTextarea1">{t('quiz.choseOption')}</label>
          <select className="form-select" aria-label="Default select example" value={report.idCategory} onChange={handleChangeCategory}>
            <option value="">{t('quiz.selectOption')}</option>
            <option value="0">{t('quiz.questionError')}</option>
            <option value="1">{t('quiz.malfunction')}</option>
            <option value="2">{t('quiz.suggestion')}</option>
          </select>
          <div className="form-group mt-3">
            <label for="exampleFormControlTextarea1">{t('quiz.descritionError')}</label>
            <textarea className="form-control" rows="8" value={report.description} onChange={handleChangeDescription}></textarea>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShowModal(false)
              clearReport()
            }}>
            {t('quiz.cancel')}
            </Button>
            <Button
              disabled={report.description === '' || report.idCategory === ''}
              variant="primary"
              onClick={() => {
              clearReport()
              sendReport()
            }}>
            {t('quiz.finish')}
            </Button>
          </Modal.Footer>
        </Modal>
        <div id='toast' class="toast align-items-center text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'absolute', top: '10%', left: '4%'}}>
          <div class="d-flex">
            <div class="toast-body">
              {messageToast}
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => closeToast()}></button>
          </div>
        </div>
      </div>
      );
}