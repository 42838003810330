import Footer from '../../../shared/components/Footer/Footer.js';
import './Home.css';
import { ArrowRight, Play } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import wallpaper from "../../../assets/img/mock4tech-bg.png";
import { px } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import config from '../../../config.js';
import { Modal, Button } from "react-bootstrap";
import { useLoading } from "../../../store/LoadingContext.js";
import i18n from 'i18next'; // Assuming you have i18n set up in your app
import { getApiLanguageCode } from '../../../utils/getApiLanguageCode.js';
import { useUserAgentContext } from '../../../store/getUserAgentDetailsContext.js';
import { UAParser } from "ua-parser-js";


const apiUrl = config.apiUrl;

const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f2f5',
    color: '#333',
    margin: 0,
    padding: 0,
  },
  heroTitle: {
    color: '#17db4e'
  },
  heroText: {
    color: '#ffffff'
  },
  heroSection: {
    backgroundImage: `url(${wallpaper})`,
    height: 500,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    padding: '100px 0',
    textAlign: 'center',
    position: 'relative',
  },
  heroOverlay: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  heroContent: {
    position: 'relative',
    zIndex: 2,
    marginTop:'50px'
  },
  sectionHeading: {
    color: '#333',
    fontSize: '2rem',
    marginBottom: '20px',
    textAlign: 'center'
  },
};

const HeroSection = () => {
  const { t } = useTranslation('home'); // Use the 'home' namespace
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <section style={styles.heroSection}>
      
      <div style={styles.heroOverlay}></div>
      <div style={styles.heroContent} className="container">
        <h3 style={styles.heroTitle} className="fs-1 transition-element mt-2">{t('hero.title')}</h3>
        <p style={styles.heroText} className="fs-4 transition-element">{t('hero.text')}</p> 
        
        <a type="button" style={{width:320}} href="#featuredCertifications" className="mt-1 btn btn-custom btn-lg transition-element">
          {t('hero.startNow')}<ArrowRight />
        </a>


        {/* <Button
          style={{width:320}}
          variant="secondary"
          className="btn btn-custom2 mt-1 ml-3 btn-lg transition-element"
          onClick={handleShow}
        >
          {t('hero.viewDemo')} <Play />
        </Button> */}

      </div>

      {/* Modal
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("hero.videoTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-wrapper">
            <iframe
              width="100%"
              height="315"
              src={t("hero.demoVideo")}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal> */}
    </section>
  );
};


const FeaturedMocks = () => {
  const { t } = useTranslation();
  const [exams, setExams] = useState([]);
  const { loading, setLoading } = useLoading();
  const languageCode = getApiLanguageCode(i18n.language);
  
  useEffect(() => {
    setLoading(true);
    fetch(`${apiUrl}/exams/home/featured/3?language=${languageCode}`)
      .then((response) => response.json())
      .then((data) => {
        setExams(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching the exams:', error);
        setLoading(false);
      });
  }, [languageCode]);

  return (
    <section className="py-5">
      <div id="featuredCertifications" className="container transition-element">
        <h2 style={styles.sectionHeading}>{t('featured.title')}</h2>
        <div className="row g-4">
          {exams.length > 0 ? (
            exams.map((exam) => (
              <MockCard
                key={exam.idExam}
                id={exam.idExam}
                img={exam.img}
                title={exam.name}
                description={exam.shortDescription}
                durationInMinutes={exam.durationInMinutes}
                totalQuestions={exam.totalQuestions}
                level={exam.level}
              />
            ))
          ) : (
            <p className="text-center">{t('featured.noResult')}</p>
          )}
        </div>
      </div>
    </section>
  );
};

const MockCard = ({ id, title, img, description, durationInMinutes, totalQuestions, level }) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-6 col-lg-4">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => { window.location.href = `/exam-quizzes/${id}`}} className="card h-100 shadow">
        <img
          style={{width: '100%', maxWidth: '60%'}} 
          src={img} 
          className="card-img-top" 
          alt={title} 
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/350x350";
          }} 
        />
        <div className="card-body text-center">
          <h5 className="card-title" style={{height:50}}>{title}</h5>
          <p className="card-text" style={{ height: '60px', overflow: 'hidden' }}>
            {description}
          </p>
          <div className="text-center" style={{height:50}}>            
            <span className="badge badge-success me-1">{level}</span>
            <span className="badge badge-success me-1">{durationInMinutes} {t('mock.minutes')}</span>
            <span className="badge badge-success me-1">{totalQuestions} {t('mock.questions')}</span>
          </div>
          <button className="btn btn-lg btnReadMore stretched-link mt-3">
            {t('mock.learnMore')}
          </button>
          
        </div>
      </div>
    </div>
  );
};

const BenefitsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5 benefits-section">
      <div className="container transition-element">
        <h2 style={styles.sectionHeading}>{t('benefits.title')}</h2>
        <div className="row text-center">
          <BenefitCard 
            icon="bi bi-award" 
            title={t('benefits.realisticMocks')} 
            description={t('benefits.realisticMocksDesc')} 
          />
          <BenefitCard 
            icon="bi bi-graph-up" 
            title={t('benefits.monitoredPerformance')} 
            description={t('benefits.monitoredPerformanceDesc')} 
          />
          <BenefitCard 
            icon="bi bi-people" 
            title={t('benefits.learningCommunity')} 
            description={t('benefits.learningCommunityDesc')} 
          />
        </div>

        {/* <iframe
              width="100%"
              height="450"
              src={t("hero.demoVideo")}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
      </div>


    </section>
  );
};

const BenefitCard = ({ icon, title, description }) => (
  <div className="col-md-4">
    <div className="mb-3">
      <i className={`${icon} display-4 text-success`}></i>
    </div>
    <h5 style={{fontWeight:'bold'}}>{title}</h5>
    <p>{description}</p>
  </div>
);

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <div className="container">
        <h2 style={styles.sectionHeading} className="text-center">{t('testimonials.title')}</h2>
        <div 
          className="d-flex flex-column flex-md-row justify-content-center align-items-stretch"
          style={{ rowGap: "1rem" }}
        >
          <div className="col-md-4 d-flex">
            <TestimonialCard 
              text={t('testimonials.testimonial1Text')}
              author={t('testimonials.testimonial1Author')}
              avatar="https://media.licdn.com/dms/image/v2/C4D03AQFaizBE3tFryg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1563377546286?e=1734566400&v=beta&t=IAWbpbiAlN6KXA5t_MwS34OuXqqw3gm69uMQYOmFCH0"
              certificationImg="https://mock4tech-static.s3.sa-east-1.amazonaws.com/certifications/ai-practitioner-plain.png"
              certificationTitle="AWS AI Practitioner"
            />
          </div>
          <div className="col-md-4 d-flex">
            <TestimonialCard 
              text={t('testimonials.testimonial2Text')}
              author={t('testimonials.testimonial2Author')}
              avatar="https://mock4tech-static.s3.sa-east-1.amazonaws.com/avatar/flavio_bastos.jpeg"
              certificationImg=""
              certificationTitle=""
            />
          </div>
        </div>
      </div>
    </section>
  );  
  
};

const TestimonialCard = ({ text, author, avatar, certificationImg, certificationTitle }) => (
  <div className="testimonial-container">
    <div className="card">
      <div className="user-info">
        <img src={avatar} alt={`${author} avatar`} className="avatar" />
        <h5 className="author-name">{author}</h5>
      </div>
      
      <p className="testimonial-text">"{text}"</p>
        <div className="certification">
          {certificationImg && certificationTitle && (
            <>
              <img src={certificationImg} alt={`Certification: ${certificationTitle}`} className="certification-img" />
              <h6 className="certification-title">{certificationTitle}</h6>
            </>
          )}
        </div>
    </div>
  </div>
);

const App = () => (
  <div style={styles.body}>
    <HeroSection />
    <BenefitsSection />
    <FeaturedMocks />
    <Testimonials />
    <Footer />
  </div>
);

export default App;
