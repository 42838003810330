// SimuladoCard.js
import React from 'react';
import config from '../../../config.js';
import ContadorEstudantes from './ContadorEstudantes.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiLanguageCode } from '../../../utils/getApiLanguageCode.js';
const apiUrl = config.apiUrl;

export const SimuladoCard = ({ quizId, certificationName, totalPeople, imageUrl, subtitle, levelClass, description, level }) => {
  const { t } = useTranslation('simuladoCard');
  const languageCode = getApiLanguageCode(i18n.language);

  // Function to handle the POST request
  const handleSimuladoStart = async () => {
    try {
      const response = await fetch(`${apiUrl}/user-quiz/${quizId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      localStorage.setItem("token", JSON.stringify(result.token));
      localStorage.setItem('code', JSON.stringify(result.code));
      window.sessionStorage.setItem('quizName', certificationName);

      // Redirect the user to the quiz page or handle success as needed
      window.location.href = `/quiz/0?direction=next&certificationName=${certificationName}`; // Assuming you have routes set up for quizzes

    } catch (error) {
      console.error('Failed to start simulado:', error);
    }
  };

  return (
    <div className="cardQuiz">
      <div className="card-header">
        {/* <h5 className="text-center">{title}</h5> */}
      </div>

      {/* Image added above the span tag */}
      <img src={imageUrl} alt="Simulado Image" style={{ width: '350px', height: '350px', display: 'block', margin: '0 auto' }} />

      <span className={`tag nivel-${levelClass} mt-3`}>{level}</span>

      <p className="mt-3"><strong>{subtitle}</strong></p>
      <p>{description}</p>
      
      <div className="mt-3">
        <ContadorEstudantes totalPeople={totalPeople}/>
      </div>

      <button onClick={handleSimuladoStart} className="back-btn btn btn-lg w-100 mt-3 text-bold"><i className="bi bi-play-circle-fill me-2"></i>{t('startQuiz')}</button>
    </div>
  );
};

