import React, { useState, useEffect } from 'react';
import "./Blog.css";
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { createBucketClient } from '@cosmicjs/sdk';
import { useLoading } from "../../../store/LoadingContext";

const cosmic = createBucketClient({
  bucketSlug: process.env.REACT_APP_BUCKET_SLUG || '',
  readKey: process.env.REACT_APP_BUCKET_READ_KEY || '',
});

// FeaturedSection Component (Carousel)
const FeaturedSection = () => {
  const { t } = useTranslation('blogHome');  // Hook to get translations

  return (
    <section className="featured-section">
      <div className="container">
        <div id="featuredCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="https://via.placeholder.com/1200x350" className="d-block w-100" alt={t('featuredSectionAlt1')} />
            </div>
            <div className="carousel-item">
              <img src="https://via.placeholder.com/1200x350" className="d-block w-100" alt={t('featuredSectionAlt2')} />
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#featuredCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">{t('previous')}</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#featuredCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">{t('next')}</span>
          </button>
        </div>
      </div>
    </section>
  );
};

const FilterBar = ({ certifications, selectedCertification, setSelectedCertification, setSearchText }) => {
  const { t } = useTranslation('blogHome');

  return (
    <div className="w-100 container mt-4 text-center">
      <div className="w-100 filter-bar d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
        <div className="w-100">
          <Dropdown as={ButtonGroup} className="w-100">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-question-count" className="w-100">
              <b>{t('certification')}</b>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item 
                  key={"ALL"}
                  onClick={() => setSelectedCertification("ALL")}
                  className={selectedCertification == "ALL" ? 'active-item' : ''}
              >
                Todas
              </Dropdown.Item>
              {certifications.map((certification) => (
                <Dropdown.Item 
                  key={certification.id}
                  onClick={() => setSelectedCertification(certification.id)}
                  className={certification.id === selectedCertification ? 'active-item' : ''}
                >
                  {certification.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="w-100">
         <input
            type="text"
            className="form-control search-bar w-100"
            placeholder={t('searchPlaceholder')}
            onChange={(e) => setSearchText(e.target.value)} // Update searchText state
          />
        </div>
      </div>
    </div>
  );
};


// ContentCard Component
const ContentCard = ({ id, text, title, image, author, date, slug, certifications }) => {
// const ContentCard = ({ image, title, description, author, date, buttonLabel }) => {
  const { t } = useTranslation('blogHome');
  const navigate = useNavigate();

  return (
    <div onClick={() => { window.location.href="/blog-article/" + slug}} className="col-md-4 content-item">
      <div className="card content-card h-100">
        <img src={image} className="card-img-top" alt={title} /> 
        <div className="card-body">
          <h4 className="card-title text-success">{title}</h4>
          <p className="card-text descriptionText" dangerouslySetInnerHTML={{ __html: text }}/>
          
          {certifications.map((cert) => (
            <div key={cert.id} className="mt-1 mb-2 w-100">
              <img src={cert.thumbnail} className="certificationThumb mt-1" alt={cert.title} /> 
            </div>
          ))}            

          <small className="card-text">{t('by')} {author} | {date}</small>
          {/* <button onClick={() => {navigate("/blog-article/" + slug)}} className="back-btn btn btn-lg w-100 mt-3 text-bold">{t('readNow')}</button> */}
        
          <button 
            onClick={() => { window.location.href="/blog-article/" + slug}}
            className="back-btn btn btn-lg w-100 mt-3 text-bold">{t('readNow')}
          </button>
        
        </div>
      </div>
    </div>
  );
};

// ContentList Component
const ContentList = ({ posts }) => {
  const { t } = useTranslation('blogHome');

  return (
    <div className="container mt-4">
      <div className="row g-4">
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <ContentCard
              key={index}
              id={post.id}
              text={post.metadata.description}
              image={post.thumbnail}
              certifications={post.metadata.certifications}
              title={post.title}
              slug={post.slug}
              // description={post.description}
              author={post.metadata.author}
              date={post.metadata.date}
              // buttonLabel={post.buttonLabel}
            />
          ))
        ) : (
          <div className="text-center">{t('noContentFound')}</div>
        )}
      </div>
    </div>
  );
};

const App = () => {
  const { t } = useTranslation('blogHome');
  const [filter, setFilter] = useState('ALL');
  const [ posts, setPosts] = useState([]);
  const [ certifications, setCertifications] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [ selectedCertification, setSelectedCertification] = useState('ALL');
  const { loading, setLoading } = useLoading();

  // const filteredContent = filter === 'all' ? contentData : contentData.filter(item => item.type === filter);
  
  const fetchCertifications = async () => {
    const certificationProps = `{
      id
      slug
      title
      thumbnail
    }`;
    const { objects: certifications }  = await cosmic.objects.find({
      type: 'certifications'
    }).props(certificationProps);
    setCertifications(certifications);
    console.log("Certifications:" + JSON.stringify(certifications));    
  };

  const fetchPosts = async () => {
    const postProps = `{
      id
      slug
      title
      thumbnail
      metadata {
        description
        certifications
        date
        author
        parent {
          title
        }
      }
    }`;

    try {
      const query = {
        type: "posts",
        ...(selectedCertification && selectedCertification !== "ALL" && {
          "metadata.certifications.id": selectedCertification,
        }),
        ...(searchText && { title: { $regex: searchText, $options: "i" } }), // Case-insensitive search
      };
  
      const { objects: posts } = await cosmic.objects.find(query).props(postProps);
      setPosts(posts || []);
      console.log("Fetched Posts:", posts);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setPosts([]); // Ensure state is reset in case of failure
    }
  
  };

  useEffect(() => {
    setLoading(true);
    fetchCertifications();
    fetchPosts();
    setLoading(false);
  }, [selectedCertification, searchText]);
  
  return (
    <div>
      {/* <FeaturedSection/> */}
      <FilterBar
        selectedCertification={selectedCertification}
        setSelectedCertification={setSelectedCertification}
        setSearchText={setSearchText}
        certifications={certifications}
      />
      <ContentList posts={posts} />
    </div>
  );
};

export default App;
