import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiLanguageCode } from '../../utils/getApiLanguageCode.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Quiz.css';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; // Import Modal from react-bootstrap
import { useLoading } from "../../store/LoadingContext";
import config from '../../config.js';
import { ImageQuiz } from './components/ImageQuiz/ImageQuiz.js';
import { Timer } from './components/Timer/Timer.js';
const apiUrl = config.apiUrl;

const QuizFooter = ({ token, currentQuestion, selectedOptions, firstOne, lastOne, handleNavigation, openConfirmationModal }) => {
  const { t } = useTranslation('quiz');

  return (
    <div className="quiz-footer row mt-3">
      {/* First Column for "Previous" button */}
      <div className="col-6">
        {!firstOne && (
          <button
            className="prev-btn btn btn-secondary w-100"
            disabled={!selectedOptions}
            onClick={() => handleNavigation(token, currentQuestion.idUserQuizQuestion, 'previous', firstOne, lastOne)}
          >
            {t('quiz.previous')}
          </button>
        )}
      </div>

      {/* Second Column for "Next" or "Finish" button */}
      <div className="col-6">
        {!lastOne && (
          <button
            className="next-btn btn btn-primary w-100"
            disabled={!selectedOptions}
            onClick={() => handleNavigation(token, currentQuestion.idUserQuizQuestion, 'next', firstOne, lastOne)}
          >
            {t('quiz.next')}
          </button>
        )}
        {lastOne && (
          <button onClick={openConfirmationModal} className="btn btn-danger w-100 text-white text-bold">
            {t('quiz.finishQuiz')}
          </button>
        )}
      </div>
    </div>
  );
};

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { loading, setLoading } = useLoading();
  const [error, setError] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const params = useParams();
  const currentQuestionId = params.questionId;  
  const [searchParams] = useSearchParams();
  const direction = searchParams.get('direction');
  const certificationName = searchParams.get('certificationName');
  const { t } = useTranslation('quiz');
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(() => {
    const saved = localStorage.getItem("token");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const languageCode = getApiLanguageCode(i18n.language);

  const fetchQuestion = async (token='', direction = 'next', currentQuestionId=0) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}/user-quiz/question-asked?language=${languageCode}&token=${token}&currentUserQuestionId=${currentQuestionId}&direction=${direction}`,
        {
          method: 'GET',
          headers: {
            'x-token': token
          }
        }
      );
      const data = await response.json();
      setCurrentQuestion(data);

      let checkedElementsIds = data.responses.filter((el) => el.checked).map((el) => el.id);
      console.log("Checked elements:" + JSON.stringify(checkedElementsIds));
      if(checkedElementsIds){
        setSelectedOptions(checkedElementsIds);  
      }
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch question');
      navigate(`/`);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchQuestion(authToken, direction, currentQuestionId);
  
      // // Update the question number and total questions from the API only when currentQuestion is available
      // if (currentQuestion && currentQuestion.questionNumer !== undefined) {
      //   setCurrentQuestionNumber(currentQuestion.questionNumer);
      // }
  
      // if (currentQuestion && currentQuestion.totalQuestion !== undefined) {
      //   setTotalQuestions(currentQuestion.totalQuestion);
      // }
    };
  
    fetchData();
  }, [authToken, currentQuestionId, direction, languageCode]);
  
  // const handleOptionChange = (id) => {
  //   setSelectedOption(id);
  // };

  const handleMultiOptionsChange = (id, type) => {
    if(type === "MULTI"){
      setSelectedOptions((prev) => 
        prev.includes(id) ? prev.filter(optionId => optionId !== id) : [...prev, id]
      );      
    }
    else if(type == "SINGLE"){
      setSelectedOptions([id]);
    }
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleNavigation = async (token, questionId, direction, firstOne, lastOne) => {  
    try {
      setLoading(true);
      const responseCode = await submitResponse(token, questionId);
      if (responseCode === 201) {
        if (direction === 'next') {
          navigate(`/quiz/${parseInt(questionId)}?direction=${direction}&certificationName=${certificationName}`);
        } else if (direction === 'previous') {
          navigate(`/quiz/${parseInt(questionId)}?direction=${direction}&certificationName=${certificationName}`);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Failed to submit response:', error);
      setError('Failed to submit response.');
    }
  };

  const Question = ({currentQuestionNumber, totalQuestions,  responses, questionName, selectedOptions, handleMultiOptionsChange, levelDescription, levelStatus }) => {
    const { t } = useTranslation('quiz');

    return (
      <div className="question-container">
        <div className='row questionHeader justify-content-between align-items-center mb-4' style={{display: 'flex', flexWrap: 'wrap'}}>
          <h6 className="col-lg-9 col-md-9 col-sm-12 questionCount text-left p-0 m-0">{t('quiz.question')} {currentQuestionNumber} {t('quiz.questionPreposition')} {totalQuestions}</h6>
          <span className={`col-lg-3 col-md-3 col-sm-12 text-left levelDescription levelDescription-${levelStatus}`}>{levelDescription}</span>
        </div>
        <h5>{questionName} </h5>
        {/* {currentQuestion.img && (<ImageQuiz src={currentQuestion.img}/>)} */}
        <div className="options-container">
          {responses && responses.length > 0 ? (
            responses.map((option) => (
              <>
                {/* Use label properly associated with input for better accessibility */}
                <div onClick={() => handleMultiOptionsChange(option.id, currentQuestion.type)}  className="option" key={option.id}>
                    <input
                      id={`option-${option.id}`} 
                      type={currentQuestion.type === 'SINGLE' ? 'radio' : 'checkbox'}
                      name="option"
                      value={option.id}
                      checked={ selectedOptions.includes(option.id)}
                      onChange={() => handleMultiOptionsChange(option.id, currentQuestion.type)} 
                    />
                    <label 
                      className="text-left"
                      htmlFor={`option-${option.id}`} // Link the label to the input by id
                      style={{ cursor: 'pointer' }} // Add cursor pointer for better UX
                    >
                      {option.name}
                    </label>
                  </div>
                  {/* {currentQuestion.img && (
                    <div style={{marginBottom: '2rem'}}>
                      <ImageQuiz src={currentQuestion.img}/>
                    </div>
                  )} */}
              </>
            ))
          ) : (
            <p>{t('quiz.noOptionsAvailable')}</p>
          )}
          
        </div>
      </div>
    );
  };
  

  const ProgressBar = ({ currentQuestion, totalQuestions }) => {
    const progressPercentage = totalQuestions
      ? ((currentQuestion / totalQuestions) * 100)
      : 0;
  
    return (
      <div className="progress mb-4">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {currentQuestion} / {totalQuestions}
        </div>
      </div>
    );
  };
  
  const submitResponse = async (token, questionId) => {
    const payload = {
      token: token,
      userQuizQuestionId: questionId, // Current question ID
      responses: selectedOptions,
    };
    const response = await fetch(`${apiUrl}/response-user-quiz`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(payload),
    });
    if (response.status === 201) {
      return response.status;
    }
    else {      
      throw new Error(`UserQuiz API returned status code ${response.status}`);
    }
  }
  
  const updateQuiz = async (token) => {
    // Define the PATCH request URL
    const patchUrl = `${apiUrl}/user-quiz/${token}`;
  
    // Make the PATCH request
    const patchResponse = await fetch(patchUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
    });
  
    if (patchResponse.status === 204) {
      // If PATCH is successful, navigate to the quiz dashboard
      navigate("/quiz-dashboard");
    } else {
      throw new Error(`PATCH request failed with status code ${patchResponse.status}`);
    }
  }

  const finishQuiz = async (token, questionId) => {
    try {
      setLoading(true);
      // Submit question answer
      if(selectedOptions){
        await submitResponse(token, questionId);
      }
      else{
        console.log("No peding answer, finishing Quiz...");
      }
      
      // Update Quiz status to finished
      await updateQuiz(token);
      setLoading(false);
    } catch (error) {
      console.error('Failed to submit response:', error);
      setError('Failed to submit response.');
    }
  };
  
  const handleConfirmFinishQuiz = () => {
    setShowConfirmationModal(false);
    finishQuiz(authToken, currentQuestion.idUserQuizQuestion);
  };
  const code = JSON.parse(localStorage.getItem('code'));


  return (
    
    <div>
      <p className='codeQuestion'>{code}</p>
      <div className="quiz-container">
        <h4 className='text-center text-bold mb-2'>{certificationName}</h4>

        {!loading && (
          <Timer 
            timeLeft={currentQuestion.remainingTimeSeconds}
            openConfirmationModal={() => setShowConfirmationModal(true)}
            currentQuiz={currentQuestion}
          />
      ) }

        {/* <ProgressBar currentQuestionNumber={currentQuestionNumber} totalQuestions={totalQuestions} /> */}

        {!loading && currentQuestion.responses && (
          <Question
            currentQuestionNumber={currentQuestion.questionNumer} totalQuestions={currentQuestion.totalQuestion}
            responses={currentQuestion.responses}
            questionName={currentQuestion.questionName}
            selectedOptions={selectedOptions}
            levelDescription={currentQuestion.levelDescription}
            levelStatus={currentQuestion.level}
            handleMultiOptionsChange={handleMultiOptionsChange}
          />
        )}

        {!loading && (
          <>
            <QuizFooter
              token={authToken}
              currentQuestion={currentQuestion}
              selectedOptions={selectedOptions}
              firstOne={currentQuestion.firstOne}
              lastOne={currentQuestion.lastOne}
              handleNavigation={handleNavigation}
              openConfirmationModal={() => setShowConfirmationModal(true)}
            />
          </>
        )}

        {/* Confirmation Modal */}
        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('quiz.finishQuiz')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('quiz.finishConfirmation')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            {t('quiz.cancel')}
            </Button>
            <Button variant="danger" onClick={handleConfirmFinishQuiz}>
            {t('quiz.finish')}
            </Button>
          </Modal.Footer>
        </Modal>
        </div>
      </div>
  );
};

const App = () => <Quiz />;

export default App;
